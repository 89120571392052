@media (max-width: 1750px) {
  header .right_header ul li a.call_link {
    font-size: 20px;
  }
}
@media (max-width: 1681px) {
  header.fixed .right_header ul li .btn_border,
  header.fixed .right_header ul li .btn_border_white {
    font-size: 15px;
    padding: 6px 20px;
  }
  header .right_header ul li {
    padding-left: 15px;
  }
  .search_box {
    max-width: 360px;
  }
  .pb-120 {
    padding-bottom: 80px;
  }
  .pt-120 {
    padding-top: 80px;
  }
  .pb-80 {
    padding-bottom: 60px;
  }
  .pt-80 {
    padding-top: 60px;
  }
  .Gallery_image_wrapper .gallery-item {
    height: 220px;
  }
  header .right_header ul li .btn_border,
  header .right_header ul li .btn_border_white {
    padding: 6px 20px;
  }
}
@media (max-width: 1501px) {
  .pagination_left {
    left: 20px;
  }
  header .right_header ul li .btn_border,
  header .right_header ul li .btn_border_white {
    font-size: 14px;
  }
}
@media (max-width: 1501px) and (min-width: 992px) {
  .location_box .location_img {
    width: 230px;
  }
  .location_box .Location_content {
    width: calc(100% - 230px);
  }
}
@media (max-width: 1441px) {
  h1,
  .h1 {
    font-size: 52px;
    line-height: 68px;
  }
  h2,
  .h2 {
    font-size: 34px;
    line-height: 44px;
  }
  h3,
  .h3 {
    font-size: 24px;
    line-height: 30px;
  }
  h4,
  .h4 {
    font-size: 18px;
    line-height: 22px;
  }
  p.big {
    font-size: 24px;
    line-height: 32px;
  }
  .newsletter_wrap {
    background-position: right -360px center;
  }
}
@media (max-width: 1400px) {
  .filter_guest_inner ul li {
    width: calc(100% / 4 - 20px);
  }
  .filter_guest_inner ul {
    justify-content: flex-start;
  }
}
@media (max-width: 1281px) {
  header .right_header {
    ul {
      li:first-child {
        display: none;
      }
    }
  }
}
@media (max-width: 1200px) {
  header .right_header {
    ul {
      li {
        a.btn_border,
        a.btn_border_white {
          border: 0;
          padding: 0 !important;
          font-size: 0 !important;
          color: #fff;
          &:hover {
            background: transparent;
            color: #fff;
          }
          i {
            font-size: 20px;
          }
        }
        &.header_email_wrap {
          // .btn_border {
          //   &::before {
          //     background: none;
          //   }
          // }
        }
      }
    }
  }
  .newsletter_wrap {
    background-position: right -500px center;
  }
  .Gallery_image_wrapper .gallery-item {
    height: 170px;
  }
  .booking_tab_wrapper
    .account_gallery_wrapper
    .gallery_wrapper
    .Gallery_image_wrapper
    .gallery-item {
    width: calc(100% / 4 - 10px);
    height: 200px;
  }
  .exclusive_form_inner h3 {
    font-size: 32px;
    margin: 0 0 15px;
    line-height: 36px;
  }
  .exclusive_form_inner h4 {
    font-size: 24px;
    margin: 0 0 20px;
    line-height: 30px;
  }
  .booking_wrap_inner {
    .btn_primary {
      font-size: 15px;
      padding: 7px 10px;
    }
  }
}
@media (max-width: 1120px) {
  .pagination_left {
    display: none;
  }
  .hire_exclusive {
    h2 {
      font-size: 45px;
    }
    ul {
      li {
        a {
          font-size: 20px;
          &.phone,
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .account_page_Wrapper,
  .booking_page_wrapper,
  .confirm_page_Wrapper,
  .contact_us_wrap {
    .inner_banner {
      height: 450px;
      .account_des {
        font-size: 24px;
        line-height: 20px;
      }
    }
  }
  .login_wrapper {
    padding-top: 100px;
    padding-bottom: 30px;
    .login_inner {
      form {
        .login_form {
          h3.page_title {
            font-size: 60px;
            line-height: 40px;
          }
          .sub_title {
            font-size: 25px;
            line-height: 10px;
            margin-bottom: 25px;
          }
        }
      }
    }
    .register_inner_wrapper {
      .register_form_wrapper {
        .register_form {
          padding-left: 10px;
          padding-right: 10px;
          h3.page_title {
            font-size: 60px;
            line-height: 40px;
          }
          .sub_title {
            font-size: 25px;
            line-height: 10px;
            margin-bottom: 25px;
          }
          form,
          .address_lookup_wrap {
            .form-group {
              position: relative;
              z-index: 0;
              input {
                height: 50px;
                font-size: 14px;
              }
            }
            .create_account {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .p-tabview .p-tabview-panels {
    padding: 30px 0;
  }

  .filter_button_right ul li {
    margin: 0 5px 5px;
  }
  .filter_button_right ul {
    justify-content: center;
    flex-wrap: wrap;
  }
  .guest_list_table_wrap {
    width: 100%;
  }
}
@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 44px;
    line-height: 60px;
  }
  h2,
  .h2 {
    font-size: 28px;
    line-height: 36px;
  }
  h3,
  .h3 {
    font-size: 22px;
    line-height: 28px;
  }
  h4,
  .h4 {
    font-size: 18px;
    line-height: 22px;
  }
  p.big {
    font-size: 20px;
    line-height: 28px;
  }
  .pb-120 {
    padding-bottom: 60px;
  }
  .pt-120 {
    padding-top: 60px;
  }
  .pb-80 {
    padding-bottom: 40px;
  }
  .pt-80 {
    padding-top: 40px;
  }
  .search_box {
    display: none;
  }
  .banner_wrapper .search_box {
    display: flex;
  }
  .search_mobile {
    display: block;
  }
  header .right_header .menu_toggle {
    padding: 20px;
    margin-right: 10px;
  }
  header .left_hedaer .logo_wrap img {
    max-height: 50px;
  }
  header .menu_wrapper {
    top: 64px;
    height: calc(100vh - 64px);
  }
  header.fixed .menu_wrapper {
    height: calc(100vh - 51px);
    top: 51px;
  }
  header .right_header ul {
    padding-right: 10px;
  }
  .banner_wrapper .banner_text_wrap {
    position: relative;
    top: 0;
    left: 0;
    transform: inherit;
    padding: 60px 20px;
    background: #000;
  }
  .Gallery_image_wrapper {
    position: relative;
  }
  .account_page_Wrapper,
  .booking_page_wrapper,
  .confirm_page_Wrapper,
  .contact_us_wrap {
    .inner_banner {
      .account_page_title,
      .booking_page_title,
      .confirm_page_title,
      .forgot_page_title {
        font-size: 60px;
        .account_des {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  .account_page_Wrapper,
  .booking_page_wrapper,
  .confirm_page_Wrapper,
  .contact_us_wrap {
    .account_wrap_inner {
      .your_booking_wrapper {
        .table_design_one {
          .table-responsive {
            .table {
              thead {
                tr {
                  th {
                    padding: 14px 10px;
                    font-size: 15px;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    font-size: 14px;
                    .booking_btn {
                      .btn_border_primary {
                        font-size: 15px;
                        padding: 10px 6px;
                      }
                    }
                    p,
                    span {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .banner_wrapper .banner_video {
    height: 450px;
    max-height: 100vh;
  }
  .banner_wrapper .mouse_scroll {
    display: none;
  }
  .about_wrap {
    background: #000;
    padding-top: 30px;
  }
  .about_box h2 {
    margin-top: -40px;
  }
  .about_wrap .about_box {
    max-width: 100%;
  }
  .about_wrap .about_box img {
    width: 100%;
    display: block;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
  .about_wrap .about_title {
    margin: 0 auto 60px;
  }
  header .menu_wrapper .bottom_menu ul li a {
    font-size: 16px;
    line-height: 22px;
  }
  .tab_wrapper nav ul li a {
    font-size: 16px;
  }
  .tab_wrapper nav ul li {
    padding: 0 15px;
  }
  .banner_wrapper.vanue_banner .banner_img {
    transform: translate(-50%, -50%);
    top: 50%;
    max-width: 350px;
  }
  .Gallery_image_wrapper .gallery-item {
    height: 230px;
    width: calc(100% / 3);
  }
  .one_feed_banner .one_feed_logo {
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
  }
  .one_feed_banner .one_feed_content {
    width: 100%;
    padding: 0 0 20px;
    text-align: center;
  }
  .one_feed_banner .one_feed_btn {
    width: 100%;
    text-align: center;
  }
  .one_feed_banner .one_feed_logo img {
    max-height: 60px;
  }
  .one_feed_banner .one_feed_content h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .location_section .hire_exclusive {
    text-align: center;
  }
  .location_section .hire_exclusive ul {
    justify-content: center;
  }
  .banner_wrapper.exclusive_banner .exclusive_banner_text {
    position: relative;
    left: 0;
    transform: inherit;
    text-align: center;
    top: 0;
    padding: 50px 0 0;
  }
  .booking_start_wrapper p {
    font-size: 18px;
    line-height: 26px;
  }
  .location_sidebar.p-sidebar .p-sidebar-header .p-sidebar-close {
    top: 5px;
    right: 5px;
  }
  .filter_guest_inner ul li {
    width: calc(100% / 3 - 20px);
  }
  footer .right_social_icon {
    right: inherit;
    top: inherit;
    transform: translate(-50%, 0);
    bottom: 0;
    left: 50%;
  }
  footer .right_social_icon ul {
    width: auto;
    display: flex;
  }
  footer .right_social_icon ul li a:hover {
    padding-right: 10px;
    width: 50px;
  }
}
@media (max-width: 855px) {
  .booking_page_wrapper {
    .booking_wrap_inner {
      .booking_tab_wrapper {
        .p-component {
          .overview_wrapper {
            .contact_detail {
              .table {
                tbody {
                  .table_padding_manage {
                    padding: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .newsletter_wrap {
    background: #06072a;
  }
  .about_wrap .about_box {
    max-width: 390px;
  }

  .tab_wrapper nav ul li a {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tab_wrapper nav ul li {
    padding: 0 8px;
  }
  .tab_wrapper nav {
    padding: 16px;
  }
  .location_section_wrap .location_inner.download_box {
    margin: 35px 0;
  }
  .menu_img {
    margin-left: -15px;
  }
  .location_sidebar.p-sidebar .p-sidebar-content ul.nav-pills li span {
    font-size: 13px;
    margin: 0px 10px 0px 10px;
  }
  .guest_list_wrapper h2,
  .email_management_wrap h2 {
    margin: 0 0 20px !important;
    text-align: center;
  }

  .guest_total_wrap ul {
    flex-wrap: wrap;
  }
  .guest_total_wrap ul li {
    // width: 50%;
    margin: 0 0 5px;
  }
  .table_design_one {
    table {
      thead {
        display: none;
      }
      tbody {
        tr {
          border-bottom: 1px solid #ddd;
          td {
            display: block;
            padding-left: 155px;
            position: relative;
            border: 0;
            font-size: 14px;
            border-bottom: 1px solid $textGray;
            padding-top: 10px;
            padding-bottom: 20px;
            line-height: 10px;
            &:last-child {
              // margin-bottom: 30px;
            }
            // &:not(:last-child) {
            &::before {
              content: 'Booking Ref';
              position: absolute;
              left: 10px;
              // top: 9px;
              // top: 0px;
              font-size: 14px;
              color: $textWhite;
              width: 150px;
            }
            // }
            &.venue {
              &:before {
                content: 'Venue';
              }
            }
            &.party_date {
              &:before {
                content: 'Party Date';
              }
            }
            &.place_booked {
              &:before {
                content: 'Places Booked';
              }
            }
            &.booking_status {
              &:before {
                content: 'Booking Status';
              }
            }
            &.places {
              &:before {
                content: 'Places';
              }
            }
            &.first_name {
              &:before {
                content: 'First Name';
              }
            }
            &.last_name {
              &:before {
                content: 'Last Name';
              }
            }
            &.manage_by {
              &:before {
                content: 'Managed By';
              }
            }
            &.invited {
              &:before {
                content: 'Invited';
              }
            }
            &.attending {
              &:before {
                content: 'Attending';
              }
            }
            &.wheelchair {
              &:before {
                content: 'Wheelchair';
              }
            }
            &.vegetarian {
              &:before {
                content: 'Vegetarian';
              }
            }
            &.selected {
              &:before {
                content: 'Selected';
              }
            }
            &.email_address {
              &:before {
                content: 'Email Address';
              }
            }
            // .booking_btn {
            //   .btn_border_primary {
            //     position: relative;
            //     margin-bottom: 20px;
            //   }
            // }
            // &:nth-child(odd) {
            //   background: #eeeeee;
            // }
            // &:last-child {
            //   border-bottom: 20px solid #fff;
            // }
            // &:first-child {
            //   border-top: 20px solid #fff;
            // }
          }
          &.expanded {
            td {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .booking_tab_wrapper
    .account_gallery_wrapper
    .gallery_wrapper
    .Gallery_image_wrapper
    .gallery-item {
    width: calc(100% / 3 - 10px);
    height: 200px;
  }
  .testimonial_slider {
    margin-top: 70px;
  }
  .exclusive_form_inner .welcome_part_exclusive_form,
  .exclusive_form_inner .exclusive_form_button {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .hire_exclusive {
    h2 {
      font-size: 35px;
    }
    ul {
      li {
        a {
          font-size: 15px;
          &.phone,
          span {
            font-size: 14px;
            i {
              font-size: 14px;
            }
          }
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
@media (max-width: 660px) {
  header .menu_wrapper .menu_inner .p-slidemenu {
    width: 100%;
    .p-slidemenu-content {
      .p-slidemenu-rootlist {
        width: 100% !important;
        .p-submenu-list {
          width: 100% !important;
        }
      }
    }
  }
  .banner_wrapper.mobile .banner_bg_mobile {
    height: 320px;
  }
  .location_box .location_img {
    width: 230px;
  }
  .location_box .Location_content {
    width: calc(100% - 230px);
  }
  .filter_guest_inner ul li {
    width: calc(100% / 2 - 20px);
  }
  .booking_page_wrapper {
    .inner_banner {
      height: 350px;
      .booking_page_title {
        font-size: 30px;
        line-height: 30px;
        margin: 0 0 10px;
        letter-spacing: 5px;
      }
      .booking_des {
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 20px;
      }
      .back_to_booking {
        .btn--primary {
          padding: 10px 14px;
        }
      }
    }
    .booking_wrap_inner {
      .booking_tab_wrapper {
        .p-component {
          .overview_wrapper {
            .contact_detail {
              .table {
                thead {
                  tr {
                    th {
                      padding: 12px;
                      font-size: 15px;
                    }
                  }
                }
                tbody {
                  .table_padding_manage {
                    tr {
                      td {
                        padding: 10px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .drink_wrapper {
          padding: 0px;
        }
      }
    }
  }
  .guest_total_wrap {
    padding: 0px;
  }
}

@media (max-width: 600px) {
  .tab_wrapper {
    display: none;
  }
  .location_box_inner {
    height: calc(100vh - 135px);
  }
  // .inner_banner {
  //   .booking_des{

  //   }
  // }
}

@media (max-width: 575px) {
  .banner_wrapper .banner_video {
    height: 300px;
  }
  .banner_wrapper .full_screen_btn {
    bottom: 10px;
    left: 10px;
  }
  h1,
  .h1 {
    font-size: 34px;
    line-height: 46px;
  }
  p.big {
    font-size: 18px;
    line-height: 26px;
  }
  header .menu_wrapper {
    padding: 20px;
    overflow-y: auto;
  }
  header .menu_wrapper .menu_inner .p-slidemenu .p-slidemenu-wrapper {
    padding-top: 30px;
  }
  header .menu_wrapper .menu_inner {
    height: 70%;
  }
  .Gallery_image_wrapper .gallery-item {
    height: 230px;
    width: calc(100% / 2);
  }
  .location_section_wrap .location_inner.download_box ul {
    flex-wrap: wrap;
  }
  .location_section_wrap .location_inner.download_box ul li {
    padding-bottom: 10px;
  }
  .drink_wrapper .drink_list_wrapper .drink_list_inner ul li {
    flex-wrap: wrap;
  }
  .drink_wrapper
    .drink_list_wrapper
    .drink_list_inner
    ul
    li
    .package_left_content {
    width: 100%;
    padding-right: 0;
  }
  .drink_wrapper .drink_list_wrapper .drink_list_inner ul li .package_price {
    width: 100%;
    margin: 15px 0 0;
  }
  .location_section .hire_exclusive ul {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .location_section .hire_exclusive ul li span {
    padding: 20px 0;
  }
  .accordian_wrapper
    .p-accordion
    .p-accordion-tab
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-header-text {
    font-size: 15px;
    line-height: 21px;
  }
  .booking_start_wrapper p {
    font-size: 16px;
    line-height: 24px;
  }
  .search_box .submit_button .btn_primary {
    font-size: 0;
    padding: 12px 20px 12px;
  }
  .login_wrapper {
    .login_inner {
      form {
        .login_form {
          padding: 30px 20px;
          h3.page_title {
            font-size: 40px;
            line-height: 40px;
          }
          P.sub_title {
            font-size: 25px;
            line-height: 10px;
            margin-bottom: 25px;
          }
        }
      }
    }
    .register_inner_wrapper {
      .register_form_wrapper {
        .register_form {
          padding: 30px 20px;
          h3.page_title {
            font-size: 40px;
            line-height: 40px;
          }
          P.sub_title {
            font-size: 25px;
            line-height: 20px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  // .inner_banner {
  //   min-height: 250px;
  //   padding: 130px 0 50px;
  // }
  .account_page_Wrapper,
  .confirm_page_Wrapper,
  .contact_us_wrap {
    .inner_banner {
      height: 250px;
      .account_page_title,
      .booking_page_title,
      .confirm_page_title,
      .forgot_page_title {
        font-size: 35px;
      }
      .account_des {
        font-size: 16px;
      }
    }
  }
  .account_page_Wrapper {
    .account_wrap_inner {
      padding-top: 20px;
    }
  }
  .location_box .location_img {
    width: 150px;
  }
  .location_box .Location_content {
    width: calc(100% - 150px);
    padding-top: 0;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  h6,
  .h6 {
    font-size: 14px;
    line-height: 18px;
  }
  .location_box .Location_content p {
    font-size: 13px;
  }
  .location_box .Location_content a {
    font-size: 14px;
  }
  .exclusive_form_inner h3 {
    font-size: 26px;
    line-height: 32px;
  }
  .exclusive_form_inner h4 {
    font-size: 20px;
    line-height: 26px;
  }
  .availability_popup
    .p-dialog-header
    .p-dialog-header-icons
    .p-dialog-header-icon {
    right: -10px;
    top: -22px;
    width: 35px;
    height: 35px;
  }
  .hire_exclusive {
    h2 {
      font-size: 30px;
    }
    ul {
      flex-direction: column;
      li {
        padding: 10px 0px;
      }
    }
  }
}

@media (max-width: 991px) and (max-height: 700px) {
  header .menu_wrapper .menu_inner {
    height: 380px;
    overflow: auto;
    margin-bottom: 20px;
  }
  header .menu_wrapper {
    overflow-y: auto;
  }
}

@media (max-width: 481px) {
  h1,
  .h1 {
    font-size: 28px;
    line-height: 40px;
  }
  h2,
  .h2 {
    font-size: 25px;
    line-height: 33px;
  }
  h3,
  .h3 {
    font-size: 20px;
    line-height: 26px;
  }
  p {
    font-size: 15px;
    line-height: 21px;
  }
  p.big,
  .menu_content p {
    font-size: 13px;
    line-height: 20px;
  }
  .menu_tab_wrap {
    .list_ul {
      padding: 0px;
      li {
        font-size: 14px;
        padding: 10px;
      }
    }
  }
  .testimonial_wrap .testimonial_inner .rating i {
    font-size: 20px;
    padding: 0px 5px 20px;
  }
  .parties_wrapper .parties_box {
    padding: 180px 20px 20px;
  }
  .social_icon ul li a i {
    font-size: 26px;
  }
  .social_icon ul li {
    padding: 0 13px;
  }
  footer .right_social_icon ul li a i {
    font-size: 20px;
  }
  footer .right_social_icon ul li a {
    height: 40px;
    width: 40px;
  }
  .one_feed_banner {
    padding: 15px;
  }
  .booking_start_wrapper p {
    font-size: 15px;
    line-height: 22px;
  }
  .know_more_wrap p {
    font-size: 16px;
    line-height: 22px;
  }
  header
    .menu_wrapper
    .menu_inner
    .p-slidemenu
    .p-slidemenu-content
    .p-slidemenu-rootlist
    .p-menuitem
    .p-menuitem-link {
    font-size: 20px;
    padding: 12px 0;
  }
  header
    .menu_wrapper
    .menu_inner
    .p-slidemenu
    .p-slidemenu-content
    .p-slidemenu-rootlist
    .p-menuitem.about_menu
    > .p-menuitem-link
    > .p-menuitem-text {
    font-size: 20px;
  }
  header
    .menu_wrapper
    .menu_inner
    .p-slidemenu
    .p-slidemenu-content
    .p-slidemenu-rootlist
    .p-menuitem.clickabel_menu
    .p-menuitem-link {
    font-size: 16px;
    padding: 11px 0;
  }
  header
    .menu_wrapper
    .menu_inner
    .p-slidemenu
    .p-slidemenu-content
    .p-slidemenu-rootlist
    .p-menuitem.menu_link
    .p-menuitem-link {
    font-size: 15px;
    padding: 5px 0;
  }
  .location_sidebar.p-sidebar .p-sidebar-content {
    padding-left: 0;
    padding-right: 0;
  }
  .filter_guest_inner ul li {
    width: 100%;
  }
  .filter_guest_inner ul {
    gap: 5px;
  }
  .radio_wrapper .form-check-inline {
    display: block;
  }
  .booking_tab_wrapper
    .account_gallery_wrapper
    .gallery_wrapper
    .Gallery_image_wrapper
    .gallery-item {
    width: calc(100% / 2 - 10px);
    height: 130px;
  }
  .more_content .more_content_innner .close_btn {
    top: -20px;
  }
  .login_wrapper {
    .login_inner {
      form {
        .login_form {
          padding: 30px 20px;
          h3.page_title {
            font-size: 30px;
            line-height: 30px;
            margin: 0 0 10px;
          }
          P.sub_title {
            font-size: 15px;
            line-height: 15px;
            margin-bottom: 15px;
          }
          .form_login_wrapper {
            .forgot_pass {
              text-align: start;
            }
            .form-group {
              input {
                font-size: 15px;
                padding: 8px 10px;
              }
            }
            .create_account {
              font-size: 13px;
            }
          }
        }
      }
    }
    .register_inner_wrapper {
      .register_form_wrapper {
        .register_form {
          padding: 30px 20px;
          h3.page_title {
            font-size: 30px;
            line-height: 30px;
            margin: 0 0 10px;
          }
          P.sub_title {
            font-size: 15px;
            line-height: 15px;
            margin-bottom: 15px;
          }
          .form-group {
            input {
              font-size: 15px;
              padding: 8px 10px;
            }
            .react_custom_select_Wrapper {
              &.square {
                .css-13cymwt-control,
                .css-t3ipsp-control {
                  padding: 0;
                }
              }
            }
          }
          .create_account {
            font-size: 13px;
          }
        }
      }
    }
  }

  .help_wrapper {
    .top_title {
      max-width: 400px;
      p {
        padding-top: 0px;
        margin-bottom: 10px;
      }
    }

    .accordian_wrapper {
      .accordion {
        .accordion-item {
          background-color: transparent;
          border: none;
          margin: 0 0 10px;
          .accordion-header {
            .accordion-button {
              font-size: 17px;
              padding: 12px;
              &:after {
                content: '';
                background-image: url('../../img/down-arrows.svg');
              }
              &:focus {
                box-shadow: none;
              }
              &:not(.collapsed) {
                box-shadow: none;
              }
            }
          }
          .accordion-collapse {
            background-color: $bgLightCoffee;
            .accordion-body {
              padding-right: 10px;
              p {
                font-size: 14px;
              }
              ul {
                list-style: disc;
                color: $textWhite;
                padding-left: 20px;
                li {
                  h6 {
                    color: $textWhite;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 411px) {
  .Gallery_image_wrapper .gallery-item {
    height: 150px;
  }
  .date_wrapper
    .booking_celender_wrapper
    .booking_celender_box
    .booking_date
    h5
    span {
    font-size: 50px;
    line-height: 40px;
  }
  .date_wrapper .booking_celender_wrapper .booking_celender_box p {
    padding: 5px;
    font-size: 14px;
  }
  header .right_header .menu_toggle {
    font-size: 24px;
  }
  header .right_header .menu_toggle {
    padding: 15px;
  }
  header .menu_wrapper,
  header.fixed .menu_wrapper {
    height: calc(100vh - 58px);
    top: 58px;
  }
  .location_sidebar.p-sidebar .p-sidebar-content ul.nav-pills li span {
    font-size: 12px;
    margin: 0px 7px 0px 7px;
  }
  .hire_exclusive {
    h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    ul {
      flex-direction: column;
      li {
        padding: 10px 0px;
        a {
          font-size: 15px;
          &.phone,
          span {
            font-size: 14px;
            i {
              font-size: 14px;
            }
          }
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .location_sidebar.p-sidebar .p-sidebar-content ul.nav-pills li span {
    margin: 0px 7px 5px 7px;
    padding: 5px 0px 5px 0px;
  }
  .location_box .location_img {
    width: 120px;
  }
  .location_box .Location_content {
    width: calc(100% - 120px);
  }
  .booking_page_wrapper {
    .booking_wrap_inner {
      .booking_tab_wrapper {
        .p-component {
          .p-tabview-nav-container {
            .p-tabview-nav {
              background: transparent;
              border-color: transparent;
              .p-tabview-nav-link {
                background: transparent;
                border: none;
                padding: 14px;
              }
              .p-highlight {
                .p-tabview-nav-link {
                  background: $primaryGradient;
                  color: $textWhite;
                  border: none;
                  border-radius: 6px;
                }
              }
            }
          }
          .overview_wrapper {
            .overview_action_wrap {
              .table {
                thead {
                  border-radius: 6px;
                  position: relative;
                  &::before {
                    content: '';
                    background: $primaryGradient;
                    opacity: 40%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    margin: 0;
                  }
                  tr {
                    border-color: transparent;
                    th {
                      padding: 10px;
                      font-size: 15px;
                    }
                  }
                }
                tbody {
                  background: $bgLightCoffee;
                  tr {
                    td {
                      background: transparent;
                      border: none;
                      font-size: 14px;
                      padding: 10px 15px;
                      vertical-align: middle;
                      a {
                        font-family: $figtree;
                        color: $primary;
                        font-size: 14px;
                        text-decoration: underline;
                      }
                      p.no_data {
                        text-align: center;
                        font-style: italic;
                        font-size: 14px;
                        margin: 0;
                      }
                    }
                    &:last-child {
                      td {
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                      }
                    }
                  }
                }
              }
            }
            .contact_detail {
              .table {
                thead {
                  //   border-radius: 6px;
                  //   position: relative;
                  //   &::before {
                  //     content: '';
                  //     background: $primaryGradient;
                  //     opacity: 40%;
                  //     width: 100%;
                  //     height: 100%;
                  //     position: absolute;
                  //     top: 0;
                  //     right: 0;
                  //     bottom: 0;
                  //     left: 0;
                  //     border-top-left-radius: 6px;
                  //     border-top-right-radius: 6px;
                  //     margin: 0;
                  //   }
                  tr {
                    border-color: transparent;
                    th {
                      padding: 10px;
                      font-size: 15px;
                    }
                  }
                }
                tbody {
                  .table_padding_manage {
                    tr {
                      td {
                        padding: 3px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .top_heading {
        h2 {
          color: $textWhite;
          margin: 0 0 20px;
        }
        p {
          color: #999;
          font-size: 18px;
          span {
            color: $textBlack;
          }
        }
      }
    }
  }
}
